/**
 * @generated SignedSource<<81b7e6e17aba154e37a89722d1f9e271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPhotoEssayArticle$data = {
  readonly articleTypes: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsPhotoEssayArticle";
};
export type helpersCheckIsPhotoEssayArticle$key = {
  readonly " $data"?: helpersCheckIsPhotoEssayArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPhotoEssayArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPhotoEssayArticle"
};

(node as any).hash = "b04045d4af75b0e15b8422e64c69d2e9";

export default node;
