import { graphql, readInlineData } from "react-relay";

import { checkIsLongReadArticle } from "scmp-app/components/article/article-render/long-read-article/helpers";
import { checkIsMorningStudioArticle } from "scmp-app/components/article/article-render/morning-studio-article/helpers";
import { checkIsPhotoEssayArticle } from "scmp-app/components/article/article-render/photo-essay-article/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { checkIsPostiesPage } from "scmp-app/components/pages/posties-pages/helpers";
import {
  checkIsMagazineSectionContent,
  checkIsSectionContent,
} from "scmp-app/components/section/helpers";
import { Variant as SectionVariant } from "scmp-app/pages/section/enums";
import type { helpersAppBarVariantBase$key } from "scmp-app/queries/__generated__/helpersAppBarVariantBase.graphql";

import type { AppBarVariant } from "./types";

export const AppBarColorScheme: Record<AppBarVariant, "light" | "dark"> = {
  "posties/brochure": "light",
  "posties/generic": "light",
  "scmp/generic-dark": "dark",
  "scmp/generic-light": "light",
  "scmp/home": "light",
  "scmp/home-slim": "light",
  "scmp/magazines-post-magazine": "light",
  "scmp/magazines-style": "light",
  "scmp/plus": "light",
  "scmp/plus/learnmore": "light",
  "scmp/scroll-over": "dark",
};

export const getAppBarVariant = (reference: helpersAppBarVariantBase$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersAppBarVariantBase on BaseWithApplicationAndUrlAlias @inline {
        ...helpersCheckIsLongReadArticle
        ...helpersCheckIsMorningStudioArticle
        ...helpersCheckIsPhotoEssayArticle
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPostiesPage
        ...helpersCheckIsPostMagazineArticle
        ...helpersCheckIsStyleArticle
        ...helpersSectionContent
        ...helpersStyleSectionContent
        ...helpersCheckIsPlusPage
      }
    `,
    reference,
  );

  if (
    checkIsStyleArticle(content) ||
    checkIsMagazineSectionContent(content) === SectionVariant.Style
  )
    return "scmp/magazines-style";

  if (checkIsMagazineSectionContent(content) === SectionVariant.PostMagazine)
    return "scmp/magazines-post-magazine";
  if (checkIsSectionContent(content)) return "scmp/home-slim";
  if (checkIsPhotoEssayArticle(content) || checkIsLongReadArticle(content))
    return "scmp/scroll-over";
  if (checkIsMorningStudioArticle(content)) return "scmp/generic-dark";
  if (checkIsPostiesArticle(content) || checkIsPostiesPage(content)) return "posties/generic";

  return "scmp/generic-light";
};
