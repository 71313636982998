/**
 * @generated SignedSource<<55f87dbf4706e1c3488d6a40514e4868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsMorningStudioArticle$data = {
  readonly articleTypes: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly sponsorType: string | null | undefined;
  readonly " $fragmentType": "helpersCheckIsMorningStudioArticle";
};
export type helpersCheckIsMorningStudioArticle$key = {
  readonly " $data"?: helpersCheckIsMorningStudioArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsMorningStudioArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsMorningStudioArticle"
};

(node as any).hash = "d33f7c516dcb2e9aafe1c67c69fcb697";

export default node;
