/**
 * @generated SignedSource<<f624dbbd754d5fcde0254b553d24ff27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsLongReadArticle$data = {
  readonly articleTypes: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsLongReadArticle";
};
export type helpersCheckIsLongReadArticle$key = {
  readonly " $data"?: helpersCheckIsLongReadArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLongReadArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsLongReadArticle"
};

(node as any).hash = "6ca905b7f65e475385abb52ddd5e815f";

export default node;
