import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { getAppBarVariant } from "scmp-app/components/app-bar/helpers";
import { getApplication } from "scmp-app/components/app-initializer/helpers";
import { checkIsPlusArticle } from "scmp-app/components/article/article-render/plus/helpers";
import { BaseEntityLink } from "scmp-app/components/base-entity-link";
import type { LinkEntity } from "scmp-app/components/base-entity-link/types";
import type { Props as BaseLinkProps } from "scmp-app/components/common/base-link";
import { checkIsMagazineSectionContent } from "scmp-app/components/section/helpers";
import { getSectionRelatedSectionsQueueName } from "scmp-app/pages/section/helpers";
import type { entityLink$key } from "scmp-app/queries/__generated__/entityLink.graphql";

export type Props = {
  children?: ReactNode;
  reference: entityLink$key;
} & Omit<BaseLinkProps, "href" | "as" | "pathname">;

export const EntityLink = forwardRef<HTMLAnchorElement, Props>((props, reference) => {
  const { children, query, reference: reference_, ...remainingProps } = props;
  const linkableEntity = useFragment(
    graphql`
      fragment entityLink on BaseWithApplicationAndUrlAlias {
        __typename
        entityId
        entityUuid
        urlAlias
        urlRedirect {
          toUrl
        }
        application {
          entityId
        }
        ... on Section {
          fullSectionPath {
            entityId
          }
          parentSection
        }
        ...helpersAppBarVariantBase
        ...helpersApplicationBase
        ...helpersCheckIsPlusArticle
        ...helpersStyleSectionContent
      }
    `,
    reference_,
  );

  const {
    __typename,
    application,
    entityId,
    entityUuid,
    fullSectionPath = [],
    parentSection,
    urlAlias,
    urlRedirect,
  } = linkableEntity;

  const isPlus = checkIsPlusArticle(linkableEntity) || query?.display?.toLowerCase() === "plus";
  const linkEntity: LinkEntity = {
    applicationId: application?.entityId,
    entityId,
    parentSection,
    routeType: isPlus ? "Plus" : __typename,
    urlAlias,
    urlRedirectPath: urlRedirect?.toUrl,
  };

  const additionalQueryParameters = {
    appBarVariant: getAppBarVariant(linkableEntity),
    application: getApplication(linkableEntity),
    entityUuid,
    parentSection: parentSection ?? "",
    relatedSectionsQueueName:
      __typename === "Section"
        ? getSectionRelatedSectionsQueueName(fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [])
        : undefined,
    sectionVariant: checkIsMagazineSectionContent(linkableEntity),
  };

  return (
    <BaseEntityLink
      linkEntity={linkEntity}
      {...remainingProps}
      query={{
        ...query,
        ...additionalQueryParameters,
      }}
      ref={reference}
    >
      {children}
    </BaseEntityLink>
  );
});

EntityLink.displayName = "EntityLink";
