import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "scmp-app/data";
import type { helpersCheckIsMorningStudioArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsMorningStudioArticle.graphql";

export const checkIsMorningStudioArticle = (reference: helpersCheckIsMorningStudioArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsMorningStudioArticle on Article @inline {
        sponsorType
        articleTypes: types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );

  const hasSponsorType = article.sponsorType !== null;
  const articleTypes =
    article.articleTypes?.flatMap(
      types => types?.value?.map(value => value?.entityId ?? "") ?? [],
    ) ?? [];
  const hasMorningStudio =
    articleTypes?.some(id => id === articleData.types.studio.entityId) ?? false;
  return hasSponsorType && hasMorningStudio;
};
