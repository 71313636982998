/**
 * @generated SignedSource<<039ad992ec7adfe89907df895d2a85c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type entityLink$data = {
  readonly __typename: string;
  readonly application: {
    readonly entityId: string;
  } | null | undefined;
  readonly entityId: string;
  readonly entityUuid: string;
  readonly fullSectionPath?: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly parentSection?: string | null | undefined;
  readonly urlAlias: string;
  readonly urlRedirect: {
    readonly toUrl: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarVariantBase" | "helpersApplicationBase" | "helpersCheckIsPlusArticle" | "helpersStyleSectionContent">;
  readonly " $fragmentType": "entityLink";
};
export type entityLink$key = {
  readonly " $data"?: entityLink$data;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityUuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Section",
  "kind": "LinkedField",
  "name": "fullSectionPath",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": "articleTypes",
  "args": null,
  "concreteType": "ArticleTypeSegment",
  "kind": "LinkedField",
  "name": "types",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleType",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v5/*: any*/)
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v7 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SectionSegment",
        "kind": "LinkedField",
        "name": "sections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Section",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v8 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPostiesArticle",
  "selections": (v7/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v9 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Page",
    "abstractKey": null
  }
],
v10 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPostiesPage",
  "selections": (v9/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v11 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v4/*: any*/)
    ],
    "type": "Section",
    "abstractKey": null
  }
],
v12 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersStyleSectionContent",
  "selections": (v11/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v13 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPlusPage",
  "selections": (v9/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "entityLink",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UrlRedirect",
      "kind": "LinkedField",
      "name": "urlRedirect",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "toUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentSection",
          "storageKey": null
        }
      ],
      "type": "Section",
      "abstractKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersAppBarVariantBase",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsLongReadArticle",
          "selections": (v6/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMorningStudioArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sponsorType",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPhotoEssayArticle",
          "selections": (v6/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        (v8/*: any*/),
        (v10/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPostMagazineArticle",
          "selections": (v7/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsStyleArticle",
          "selections": (v7/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSectionContent",
          "selections": (v11/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        (v12/*: any*/),
        (v13/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersApplicationBase",
      "selections": [
        (v8/*: any*/),
        (v10/*: any*/),
        (v13/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPlusArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaywallType",
              "kind": "LinkedField",
              "name": "paywallTypes",
              "plural": true,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v12/*: any*/)
  ],
  "type": "BaseWithApplicationAndUrlAlias",
  "abstractKey": "__isBaseWithApplicationAndUrlAlias"
};
})();

(node as any).hash = "2a0851b93cf1f88e0cc01e0f7c124f30";

export default node;
