import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "scmp-app/data";
import type { helpersCheckIsLongReadArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsLongReadArticle.graphql";

export const checkIsLongReadArticle = (reference: helpersCheckIsLongReadArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsLongReadArticle on Article @inline {
        articleTypes: types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const articleTypes =
    article.articleTypes?.flatMap(
      types => types?.value?.map(value => value?.entityId ?? "") ?? [],
    ) ?? [];
  return articleTypes?.some(id => id === articleData.types.longRead.entityId) ?? false;
};
