/**
 * @generated SignedSource<<d0a4fef95f9a061bb6c3961786ca4eaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersAppBarVariantBase$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLongReadArticle" | "helpersCheckIsMorningStudioArticle" | "helpersCheckIsPhotoEssayArticle" | "helpersCheckIsPlusPage" | "helpersCheckIsPostMagazineArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsPostiesPage" | "helpersCheckIsStyleArticle" | "helpersSectionContent" | "helpersStyleSectionContent">;
  readonly " $fragmentType": "helpersAppBarVariantBase";
};
export type helpersAppBarVariantBase$key = {
  readonly " $data"?: helpersAppBarVariantBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarVariantBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersAppBarVariantBase"
};

(node as any).hash = "61096d519940c8670e59b675d8771ba6";

export default node;
